import axios from 'axios';
// Default rating and count are hard coded to match with current App Store rating and is used only when api call fails.
// Note: This value should be kept in sync with App Store rating.
export const DEFAULT_RATING = 4.8;
export const DEFAULT_USER_RATING_COUNT = 256072;
export const appRating = {
    data: {
        rating: DEFAULT_RATING,
        ratingCount: DEFAULT_USER_RATING_COUNT,
    },
};
const formatApiData = (rating, ratingCount) => {
    try {
        return {
            data: {
                rating: Number(rating.toFixed(1)),
                ratingCount,
            },
        };
    }
    catch {
        return appRating;
    }
};
export const initializeAppRating = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: 'https://itunes.apple.com/lookup?id=499097243',
        });
        const { averageUserRating, userRatingCount } = response.data.results[0] || {};
        if (!(averageUserRating && userRatingCount))
            throw new Error('No Results');
        const formattedResponse = formatApiData(averageUserRating, userRatingCount);
        appRating.data.rating = formattedResponse.data.rating;
        appRating.data.ratingCount = formattedResponse.data.ratingCount;
    }
    catch {
        appRating.data.rating = DEFAULT_RATING;
        appRating.data.ratingCount = DEFAULT_USER_RATING_COUNT;
    }
    return appRating;
};
