import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { useEffect } from 'react';
import GTMUtils from 'utils/gtm';
export const useSearchGtmTracking = () => {
    const { searchRequestId } = useReduxSearchState();
    useEffect(() => {
        GTMUtils.push({
            event: 'pageview',
            page: 'search',
        });
    }, [searchRequestId]);
};
