import React from 'react';
import { useSelector } from 'react-redux';
import useConfig from 'hooks/use-config';
import { Box, Flex, Text } from '@spothero/ui';
import { EventListItem, ShowMore } from '../event-empty-state-svg';
import EventEmptyStateSvg from '../event-empty-state-svg/EventEmptyStateSvg';
export const UpcomingEvents = () => {
    const { siteUrl } = useConfig();
    const partner = useSelector((state) => state.searchRequest.partner);
    const mapEventItem = (event, index) => {
        if (event) {
            const { id } = event;
            const eventUrl = `${siteUrl}/search?kind=event&id=${id}&hide_event_modal=true${partner ? `partner=${partner}` : ''}`;
            return (<EventListItem key={id} event={event} eventUrl={eventUrl} index={index}/>);
        }
        return undefined;
    };
    const events = useSelector((state) => state.destination.data.events || []).map(mapEventItem);
    const hasEvents = Boolean(events?.length);
    const eventList = hasEvents ? (<Box marginBottom="7">
            <ShowMore>{events}</ShowMore>
        </Box>) : null;
    return (
    // below adds scrolling on events, NO INDICATOR currently.  Height assumed bit taller than largest from design system
    <Box overflow="auto" maxHeight={{ desktop: '30rem', base: 'none' }} data-testid="UpcomingEvents">
            {hasEvents ? (
        //   Leave space for NotGoingToEvent component at bottom
        <Box data-testid="UpcomingEvents-hasEvents" maxHeight="calc(100vh - 22rem)" overflow="auto">
                    {eventList}
                </Box>) : (<Flex flexDirection="column" justifyContent="center" alignItems="center" minHeight={60} textAlign="center">
                    <EventEmptyStateSvg />
                    <Text marginTop={4} data-testid="event-upcoming-text">
                        There are no upcoming events at this venue. <br />
                        Please try again later.
                    </Text>
                </Flex>)}
        </Box>);
};
