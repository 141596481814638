import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@spothero/ui';
import { OPTIMIZELY_VARIATION_REFRESHED, useFeatureVariation, } from 'plugins/optimizely/hooks/use-feature-variation';
import { IMMEDIATE_FEATURES } from 'utils/experiment';
export const MonthlyHelp = () => {
    const isMonthly = useSelector((state) => state.searchRequest.monthly);
    const monthlyPhoneNumber = useSelector((state) => state.city.data.monthly_phone_number);
    const monthlyPhoneNumberFriendly = useSelector((state) => state.city.data.monthly_phone_number_friendly);
    const isSearchRevampRefreshedVariation = useFeatureVariation(IMMEDIATE_FEATURES.ACQUISITION_MOBILE_WEB_SEARCH_REVAMP) === OPTIMIZELY_VARIATION_REFRESHED;
    if (!isMonthly || !isSearchRevampRefreshedVariation)
        return null;
    return (<Text fontSize="sm" paddingX="3" paddingY="2" marginTop="2" marginBottom="4" background="white" borderRadius="10px" border="1px solid" borderColor="gray.200">
            Need help? Call us at{' '}
            <Text as="a" href={`tel:${monthlyPhoneNumber}`} fontWeight="semibold" fontSize="inherit">
                {monthlyPhoneNumberFriendly}
            </Text>
        </Text>);
};
