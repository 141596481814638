import React from 'react';
import UrlUtils from '@spothero/utils/url';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import AirportAPI, {getAirportSuggestions} from 'api/airport';
import {loadable} from 'router/routes';
import {createCleanedData, fetchCleanedData} from 'router/router-utils';
import {Page} from 'utils/page-utils';
import getInitialStateCityContext from 'pages/search-transient-event-monthly/redux/get-initial-state-city-context';
import getInitialStateDestinationContext from 'pages/search-transient-event-monthly/redux/get-initial-state-destination-context';
import DestinationAPI from 'api/destination';
import SearchAirportPage from 'pages/search-airport';
import EventsAPI from 'api/events';
import {isMobilePortraitView} from 'hooks/use-is-mobile';
import useSearchViewParam from 'hooks/use-search-view-param';
import {Box} from '@spothero/ui';
import SHLoader from 'common/loader';
import {GoogleMapsProvider} from 'common/google-map-context';
import {
    OPTIMIZELY_VARIATION_REFRESHED,
    useFeatureVariation,
} from 'plugins/optimizely/hooks/use-feature-variation';
import {IMMEDIATE_FEATURES} from 'utils/experiment';
import {ResponsiveSearch} from './ResponsiveSearch';
import {useFetchStatesWithFeeIncluded} from 'pages/search/hooks/total-price-toggle/use-fetch-states-with-fee-included';
import {useFirebase} from 'hooks/use-firebase';
import {useSyncTotalPriceToggleOnSearch} from 'pages/search/hooks/total-price-toggle/use-sync-total-price-toggle-on-search';
import {useSyncTotalPriceToggleOnMount} from 'pages/search/hooks/total-price-toggle/use-sync-total-price-toggle-on-mount';
import StorageUtils from '@spothero/utils/storage';
/*
    The Search route corresponds to different page components based on a few factors:
    - whether this is a transient, monthly, event, or airport search
    - whether this is desktop or mobile experience
    - whether the location context of a search is address, city, destination, or event

    This route could be simplified by doing the following:
    - Merging mobile and desktop features

    This could also be simplified by dropping product features from the search experience,
    which is less likely to occur. It is likely that as SpotHero grows, these
    experiences will become increasingly differentiated.

    The primary reason for housing all of these different experiences under the
    same /search URL + query parameters is that it makes the marketing dept's strategy
    and work simpler: Marketing and Partners only need to determine the associated
    query parameters to append to the base URL.

    https://spothero.atlassian.net/wiki/spaces/CONS/pages/1186529350/Marketing+Search+URL+Strategy
    https://spothero.atlassian.net/wiki/spaces/CONS/pages/1351582635/Search+URLS+-+What+to+Keep+What+to+Deprecate
*/

const SearchMobileTransient = loadable(() =>
    import('pages/search-transient-event-monthly/SearchMobileTransient')
);
const SearchMobileMonthly = loadable(() =>
    import('pages/search-transient-event-monthly/SearchMobileMonthly')
);
const SearchMobileEvent = loadable(() =>
    import('pages/search-transient-event-monthly/SearchMobileEvent')
);
const SearchMobileDestination = loadable(() =>
    import('pages/search-transient-event-monthly/SearchMobileDestination')
);
const SearchDesktopTransient = loadable(() =>
    import('pages/search-transient-event-monthly/SearchDesktopTransient')
);
const SearchDesktopMonthly = loadable(() =>
    import('pages/search-transient-event-monthly/SearchDesktopMonthly')
);
const SearchDesktopEvent = loadable(() =>
    import('pages/search-transient-event-monthly/SearchDesktopEvent')
);
const SearchDesktopDestination = loadable(() =>
    import('pages/search-transient-event-monthly/SearchDesktopDestination')
);

const Search = ({isMonthly, destination, pageType, isPowerBooking}) => {
    const storageKey = `${IMMEDIATE_FEATURES.ACQUISITION_MOBILE_WEB_SEARCH_REVAMP}_impression_generated`;
    const isOptimizelyInitialized =
        StorageUtils.get(storageKey, 'session') || 'no';

    useFirebase();
    useFetchStatesWithFeeIncluded();
    useSyncTotalPriceToggleOnMount();
    useSyncTotalPriceToggleOnSearch();

    // Generate impression for the new search experience
    useFeatureVariation(
        IMMEDIATE_FEATURES.ACQUISITION_MOBILE_WEB_SEARCH_REVAMP,
        {
            generateImpression:
                typeof window !== 'undefined' &&
                isOptimizelyInitialized === 'no',
        }
    );
    StorageUtils.set(storageKey, 'yes', 'session');

    const isBrowser = typeof window !== 'undefined';
    const isSearchRevampRefreshedVariation =
        useFeatureVariation(
            IMMEDIATE_FEATURES.ACQUISITION_MOBILE_WEB_SEARCH_REVAMP
        ) === OPTIMIZELY_VARIATION_REFRESHED;
    const loading = useSearchViewParam();
    const isMobile = isMobilePortraitView();
    const isAirport = Boolean(destination?.airport);

    if (loading) {
        return (
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={1000}
                background="white"
                textAlign="center"
                padding={8}
            >
                <SHLoader />
            </Box>
        );
    }

    if (
        isMobile &&
        isSearchRevampRefreshedVariation &&
        isBrowser &&
        !isAirport // airport search is not supported in the new search experience
    ) {
        return (
            <GoogleMapsProvider>
                <ResponsiveSearch />
            </GoogleMapsProvider>
        );
    }

    if (isMonthly || isPowerBooking) {
        return isMobile ? (
            <GoogleMapsProvider>
                <SearchMobileMonthly />
            </GoogleMapsProvider>
        ) : (
            <GoogleMapsProvider>
                <SearchDesktopMonthly />
            </GoogleMapsProvider>
        );
    }

    if (destination?.airport) {
        return (
            <GoogleMapsProvider>
                <SearchAirportPage />
            </GoogleMapsProvider>
        );
    }

    if (isMobile) {
        return pageType === 'event' ? (
            <GoogleMapsProvider>
                <SearchMobileEvent />
            </GoogleMapsProvider>
        ) : !isEmpty(destination) ? (
            <GoogleMapsProvider>
                <SearchMobileDestination />
            </GoogleMapsProvider>
        ) : (
            <GoogleMapsProvider>
                <SearchMobileTransient />
            </GoogleMapsProvider>
        );
    }

    return pageType === 'event' ? (
        <GoogleMapsProvider>
            <SearchDesktopEvent />
        </GoogleMapsProvider>
    ) : !isEmpty(destination) ? (
        <GoogleMapsProvider>
            <SearchDesktopDestination />
        </GoogleMapsProvider>
    ) : (
        <GoogleMapsProvider>
            <SearchDesktopTransient />
        </GoogleMapsProvider>
    );
};

const AirportSearchInitialState = ({state, cityId, destination, query}) => {
    const {
        relative_url: slug,
        airport: {iata_code: iataCode},
    } = destination;

    return SearchAirportPage.getInitialState({
        state: {
            ...state,
            destination: {data: destination},
        },
        params: {city: cityId, slug, iata: iataCode},
        query,
    });
};

function searchCity({id, state, query, cookies}) {
    const city = `${id}`;

    return getInitialStateCityContext({
        state,
        params: {city},
        query,
        cookies,
    });
}

async function searchDestination({id, state, query, cookies}) {
    const destination = await DestinationAPI.get(id).then(res => res.data.data);
    const {relative_url: slug, spothero_city: cityId, airport} = destination;

    if (airport) {
        return AirportSearchInitialState({
            state,
            cityId,
            destination,
            query,
        });
    } else {
        return getInitialStateDestinationContext({
            state: {
                ...state,
                destination: {data: destination},
            },
            params: {city: cityId, slug},
            query,
            cookies,
        });
    }
}

async function searchAirport({id, state, query}) {
    const {
        data: {data: destination},
    } = await AirportAPI.getByCode(id);
    const {spothero_city: cityId} = destination;

    return AirportSearchInitialState({
        state,
        cityId,
        destination,
        query,
    });
}

async function searchEvent({query, state, paramOverrides}) {
    const result = await fetchCleanedData({
        state,
        route: {
            location: {
                pathname: '/search',
                search: `?${UrlUtils.createQueryString(query)}`,
            },
        },
        paramOverrides,
    });

    const {
        destination: {
            data: {id: destinationId, has_upcoming_events: hasEvents},
        },
    } = result;

    if (hasEvents) {
        const {
            data: {
                data: {results: events},
            },
        } = await EventsAPI.get({destinationId});

        result.destination.data.events = events;
    }

    return result;
}

Search.getInitialState = ({state, query, route, cookies}) => {
    const {
        location: {state: {searchParams = {}} = {}},
    } = route;
    const {id, kind} = query;
    const paramOverrides = {
        filterSpot: query.filterSpot,
        partner: query.partner,
    };
    const methodParams = {
        state,
        searchParams,
        query,
        cookies,
        route,
    };

    if (!isNil(kind)) {
        // new /search experience with kind and id provided in query string to determine how search should behave
        if (kind === 'address' && !isEmpty(searchParams)) {
            // address searches from the UI do not have an ID so we use the cleaned params returned by the search-params
            // endpoint, which has already happened in the search controls, to execute the proper search and
            // `searchParams` is passed through the location 'state' in order to route using search metadata
            // that means there is no need to call the search-params endpoint since they're already available
            // and happens after initial page load when the user performs a search through the UI
            const {city, search: searchState} = state;

            // This overrides incorrect starts/ends coming back from the search-params response
            // This is only necessary due to way Search.getInitialState handles address search pages
            // TODO: Remove once BE issue is resolved https://spothero.atlassian.net/browse/CONWEB-291

            if (query.power_booking) {
                const periodStarts = query?.starts?.split(',')?.sort();
                const periodEnds = query?.ends?.split(',')?.sort();
                const powerBookingPeriods = [];

                if (periodStarts.length === periodEnds.length) {
                    periodStarts.forEach((starts, index) => {
                        powerBookingPeriods.push({
                            starts,
                            ends: periodEnds[index],
                        });
                    });
                }

                searchParams.starts = periodStarts.length
                    ? periodStarts[0]
                    : searchParams.starts;
                searchParams.ends = periodEnds.length
                    ? periodEnds[0]
                    : searchParams.ends;
                searchParams.powerBookingPeriods = powerBookingPeriods;
                searchParams.powerBooking = true;
                searchParams.powerBookingSource = query.pb_source || null;
            } else {
                searchParams.starts = query.starts || searchParams.starts;
                searchParams.ends = query.ends || searchParams.ends;
            }

            // eslint-disable-next-line camelcase
            searchParams.search_string =
                query.search_string || searchParams.search_string;

            return createCleanedData({
                currentCity: city,
                cleanedParams: {...searchParams, ...paramOverrides},
                currentSearchState: searchState,
            });

            // if searchParams is empty, that means this search was initialized on page load and not through the UI
            // just allow it to fall through to the bottom of this method where we will fetch new cleaned data based on
            // the url that the user has provided in the address bar
        } else if (!isNil(id)) {
            methodParams.id = id;

            switch (kind) {
                case Page.CITY:
                    return searchCity(methodParams);

                case Page.DESTINATION:
                    return searchDestination(methodParams);

                case Page.AIRPORT:
                    return searchAirport(methodParams);

                case Page.EVENT:
                    query.eid = id;

                    // translate /search?kind=event&id=1 URL to /search?eid=1 for calling fetchCleanedData since it only responds with
                    // that format for event kind searches, otherwise it would error without altering the BE to accept this type of URL
                    return searchEvent({query, state, paramOverrides});
            }
        }
    }

    // regular address search on initial page load, run a new search based on the url
    return fetchCleanedData({state, route, paramOverrides});
};

Search.getInitialSearchState = async () => {
    const airports = await getAirportSuggestions();

    return {
        airports,
    };
};

Search.propTypes = {
    isMonthly: PropTypes.bool,
    destination: PropTypes.object,
    pageType: PropTypes.string,
    isPowerBooking: PropTypes.bool,
};

export default Search;
