import AirportAPI from 'api/airport';
import trackShowMobileSearchListView from 'segment/events/show-mobile-search-list-view';
import trackShowMobileSearchMapView from 'segment/events/show-mobile-search-map-view';
import trackSearchSortOrderChanged from 'segment/events/search-sort-order-changed';
import { SearchPageView } from 'utils/search-page-view';
import { paramNameOptions, updateRouteParam } from 'utils/url-utils';
import { persistSearchOversizePopoverVisible, persistSearchVehicle, } from 'utils/search-vehicle';
export const SEARCH_SUBMIT = 'SEARCH_SUBMIT';
export const SEARCH_GET_AIRPORTS = 'SEARCH_GET_AIRPORTS';
export const SEARCH_SET_SORT_TYPE = 'SEARCH_SET_SORT_TYPE';
export const SEARCH_UPDATE_TIMES = 'SEARCH_UPDATE_TIMES';
export const SEARCH_SET_NO_SPOTS_FOUND = 'SEARCH_SET_NO_SPOTS_FOUND';
export const SEARCH_SET_ZOOM = 'SEARCH_SET_ZOOM';
export const SEARCH_PAN_MAP = 'SEARCH_PAN_MAP';
export const SEARCH_SET_PAGE_VIEW = 'SEARCH_SET_PAGE_VIEW';
export const SEARCH_SET_SEARCH_CONTROLS_VISIBILITY = 'SEARCH_SET_SEARCH_CONTROLS_VISIBILITY';
export const SEARCH_SET_RECENTLY_VIEWED = 'SEARCH_SET_RECENTLY_VIEWED';
export const SEARCH_VEHICLE_PROFILE = 'SEARCH_VEHICLE_PROFILE';
export const SEARCH_OVERSIZE_POPOVER_VISIBLE = 'SEARCH_OVERSIZE_POPOVER_VISIBLE';
export const SEARCH_SET_PRICE_BREAKDOWN_MODAL_SPOT_ID = 'SEARCH_SET_PRICE_BREAKDOWN_MODAL_SPOT_ID';
export const SEARCH_SET_STATE_WITH_FEE_INCLUDED = 'SEARCH_SET_STATE_WITH_FEE_INCLUDED';
export const SEARCH_SET_COMPONENT_IS_OPEN = 'SEARCH_SET_COMPONENT_IS_OPEN';
export const SEARCH_SET_COMPONENT_EVENT_VIEW = 'SEARCH_SET_COMPONENT_EVENT_VIEW';
export const getAirports = () => ({
    type: SEARCH_GET_AIRPORTS,
    payload: AirportAPI.getAirports(),
});
export const setSearchComponentIsOpen = (payload) => {
    return {
        type: SEARCH_SET_COMPONENT_IS_OPEN,
        payload,
    };
};
export const setSearchComponentEventView = (payload) => {
    return {
        type: SEARCH_SET_COMPONENT_EVENT_VIEW,
        payload,
    };
};
export const setSortType = (sortBy, isSegmentTrackingEnabled = true) => {
    return (dispatch, getState) => {
        dispatch({
            type: SEARCH_SET_SORT_TYPE,
            payload: { sortBy },
        });
        if (isSegmentTrackingEnabled) {
            const state = getState();
            trackSearchSortOrderChanged({ state, args: { sortBy } });
        }
    };
};
export const setSearchPageView = ({ view, isTrackingEnabled = true, }) => {
    if (isTrackingEnabled) {
        if (view === SearchPageView.MOBILE_LIST) {
            trackShowMobileSearchListView();
        }
        else if (view === SearchPageView.MOBILE_MAP) {
            trackShowMobileSearchMapView();
        }
    }
    // Update the URL with the new view
    updateRouteParam(paramNameOptions.VIEW, view);
    return {
        type: SEARCH_SET_PAGE_VIEW,
        payload: { view },
    };
};
/**
 * Action to set recently viewed spot - used for map state to indicate if the user has seen the spot or not
 *
 * @param {number|string} spotId - Id of spot that was viewed
 * @returns {void}
 */
export const setRecentlyViewed = (spotId) => {
    return (dispatch, getState) => {
        const state = getState();
        const { search: { recentlyViewedSpots: recentlyViewedPrevious }, } = state;
        const newArray = Array.isArray(recentlyViewedPrevious)
            ? [...recentlyViewedPrevious, spotId]
            : [spotId];
        // Below ensures this there are only unique numbers in array
        const recentlyViewedSpots = new Set(newArray);
        dispatch({
            type: SEARCH_SET_RECENTLY_VIEWED,
            payload: { recentlyViewedSpots: [...recentlyViewedSpots] },
        });
    };
};
/* V2-CLEANUP
Refactor setZoom and the corresponding analytics middleware when Search V2 Integration is complete.
At the very least, the payload of setZoom should be made clear.
We do not need to store the zoom level in redux.
*/
export const setZoom = (payload) => ({
    type: SEARCH_SET_ZOOM,
    payload,
});
/* V2-CLEANUP
Refactor panMap and the corresponding analytics middleware when Search V2 Integration is complete.
At the very least, the payload of panMap should be made clear.
*/
export const panMap = (payload) => ({
    type: SEARCH_PAN_MAP,
    payload,
});
export const setSearchVehicle = (vehicle) => {
    persistSearchVehicle(vehicle);
    return (dispatch) => {
        dispatch({
            type: SEARCH_VEHICLE_PROFILE,
            payload: vehicle,
        });
    };
};
export const setSearchVehiclePopoverVisible = (visible) => {
    persistSearchOversizePopoverVisible(visible);
    return (dispatch) => {
        dispatch({
            type: SEARCH_OVERSIZE_POPOVER_VISIBLE,
            payload: visible,
        });
    };
};
export const setSearchControlsVisibility = (payload) => ({
    type: SEARCH_SET_SEARCH_CONTROLS_VISIBILITY,
    payload,
});
export const setPriceBreakdownModalSpotId = (spotId, rate) => ({
    type: SEARCH_SET_PRICE_BREAKDOWN_MODAL_SPOT_ID,
    payload: { spotId, rate },
});
export const resetPriceBreakdownModalSpotId = () => ({
    type: SEARCH_SET_PRICE_BREAKDOWN_MODAL_SPOT_ID,
    payload: { spotId: null, rate: null },
});
export const setStatesWithFeeIncluded = (payload) => ({
    type: SEARCH_SET_STATE_WITH_FEE_INCLUDED,
    payload,
});
