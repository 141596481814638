import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
/**
 * ### trackSwitchedVerticalTabs
 * Sends event 'Switched Vertical Tabs' to Segment.
 * Triggered when user opens switched tabs on the home page or a midfunnel page.
 *
 * @function trackSwitchedVerticalTabs
 * @param {string} pageType
 * The page it comes from - "home" or midfunnel
 * @param {string} previousTab
 * Previous tab the user was on
 * @param {string} selectedTab
 * The current tab the user is on
 * @returns {void}
 */
export default function trackSwitchedVerticalTabs({ pageType = '', previousTab = '', selectedTab = '', }) {
    try {
        SegmentUtils.track({
            event: 'Switched Vertical Tabs',
            properties: {
                page_type: pageType,
                previous_tab: previousTab,
                selected_tab: selectedTab,
            },
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackSwitchedVerticalTabs',
        });
    }
}
