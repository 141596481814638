import { Box, Flex, useBreakpointValue } from '@spothero/ui';
import React, { useCallback } from 'react';
import { SearchComponentStoreProvider, SearchComponentMobileToggle, } from '@spothero/search-component';
import { FindParkingSpotsButton } from 'pages/search/components/FindParkingSpotsButton';
import { useGoogleMapsLibraries } from 'common/google-map-context';
import { PoweredByGoogle } from '@spothero/ui-backlog';
import useConfig from 'hooks/use-config';
import { useDispatch, useSelector } from 'react-redux';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { SearchPageView } from 'utils/search-page-view';
import MobileViewToggle from './mobile-view-toggle';
import { VenueEventSEMEdit } from '../events/landing/VenueEventLandingEdit';
import { FilterSelection } from './filters/FilterSelection';
import { NotAModal } from './not-a-modal/NotAModal';
import { useShowEventsData } from 'pages/search/hooks/use-show-events-data';
import { setSearchComponentIsOpen, setSearchComponentEventView, } from 'store/search/search-actions';
import { useDefaultSuggestion } from 'pages/search/hooks/use-default-suggestion';
import { mapReduxSearchStateToSearchComponentState } from 'pages/search/utils/state-mapping-utils';
import { trackSearchEditButtonClicked } from 'segment/events/search-edit-button-clicked';
import trackEventListItemSelected from 'segment/events/search-component/event-list-item-selected';
import trackSearchSuggestionSelected from 'segment/events/search-component/search-suggestion-selected';
import trackDateEntered from 'segment/events/search-component/date-entered';
import trackErrorMessageDisplayed from 'segment/events/search-component/error-message-displayed';
import trackPowerBookingOpened from 'segment/events/search-component/power-booking-opened';
import trackFindParkingNearMeClicked from 'segment/events/search-component/find-parking-near-me-clicked';
import trackSwitchedVerticalTabs from 'segment/events/search-component/switched-vertical-tabs';
// eslint-disable-next-line no-empty-function
const handler = () => { };
const spotListMobileListViewStyles = {
    marginTop: '52px', // leave space for header
};
const ToggleWrapper = ({ onOpen }) => {
    return (<Flex paddingX="4">
            <SearchComponentMobileToggle onClick={onOpen}/>
            <MobileViewToggle />
        </Flex>);
};
export const SearchComponentContainer = () => {
    const { showSEMEvent } = useShowEventsData();
    const dispatch = useDispatch();
    const searchOnClick = () => dispatch(setSearchComponentEventView('selectWFilter'));
    const isOpen = useSelector((state) => state.search.data.searchComponentIsOpen);
    const searchComponentKey = useSelector((state) => state.searchRequest.searchComponentKey);
    const { googleMapsLoaded, googleMapsLibraries } = useGoogleMapsLibraries();
    const { siteUrl } = useConfig();
    const isMobile = useBreakpointValue({ base: true, tablet: false }, { ssr: false });
    const defaultSuggestion = useDefaultSuggestion();
    const reduxState = useReduxSearchState();
    const searchComponentState = mapReduxSearchStateToSearchComponentState({
        parkingType: reduxState.parkingType,
        starts: reduxState.startsDate,
        ends: reduxState.endsDate,
        powerBookingSource: reduxState.powerBookingSource,
        powerBookingPeriods: reduxState.powerBookingPeriods,
    });
    const onOpen = () => {
        trackSearchEditButtonClicked({
            parkingType: reduxState.parkingType,
            isMobile,
        });
        dispatch(setSearchComponentIsOpen(true));
    };
    const handleTrackVerticalTabs = useCallback(({ previousTab, selectedTab, }) => {
        trackSwitchedVerticalTabs({
            pageType: 'homepage',
            previousTab,
            selectedTab,
        });
    }, []);
    const clientRendered = {
        googleMapsLibraries,
        searchEvents: [],
        handleOnChangeForEvents: handler,
        isMobile,
        timezone: reduxState.timezone,
    };
    const providerProps = {
        siteUrl,
        eventDestinationsHash: {},
        locationBias: {},
        airports: reduxState.airports,
        parkingType: searchComponentState.parkingType,
        parkingSubType: searchComponentState.parkingSubType,
        startDate: searchComponentState.startDate,
        endDate: searchComponentState.endDate,
        monthlyStartDate: searchComponentState.monthlyStartDate,
        dates: searchComponentState.powerBookingDates,
        startTime: searchComponentState.powerBookingStartTime,
        endTime: searchComponentState.powerBookingEndTime,
        events: reduxState.events,
        // https://github.com/spothero/fe-monorepo/blob/f7be629a079b49b8a056af5d85316b9442a0acae/packages/consumer/search-component/src/search-widget/store/common.tsx#L157
        eventPanel: {
            searchOnClick,
        },
        defaultSuggestion,
        trackEventListItemSelected,
        trackSearchSuggestionSelected,
        trackDateEntered,
        trackErrorMessageDisplayed,
        trackFindParkingNearMeClicked,
        trackSwitchedVerticalTabs: handleTrackVerticalTabs,
        trackPowerBookingOpened,
        PoweredByGoogle,
        dateTimePickerButtonLabel: 'Submit',
        FindParkingSpotsButton,
    };
    if (!googleMapsLoaded) {
        return <div />;
    }
    const styles = reduxState.view === SearchPageView.MOBILE_LIST
        ? spotListMobileListViewStyles
        : {};
    return (<Flex justifyContent="center" backgroundColor="white" sx={{
            '.SearchWidget > div': {
                marginTop: '0', // Clear extra margins from SearchWidget elements
            },
            ...styles,
        }} width="100%" position="relative">
            <Box flexGrow={1} overflow="hidden" zIndex={isOpen ? 'layer5' : 0} // 2 more than .SpotMap css - .Search-map-view .SpotList, .ResponsiveSearchLayout-zone-2 .SpotList
     backgroundColor="white" paddingY={4} key={searchComponentKey}>
                <SearchComponentStoreProvider locationId={undefined} locationKind={undefined} clientRendered={clientRendered} eventMode="search" eventPanel {...providerProps}>
                    {!isOpen ? (<Box>
                            {showSEMEvent ? (<VenueEventSEMEdit />) : (<>
                                    <ToggleWrapper onOpen={onOpen}/>
                                    <FilterSelection />
                                </>)}
                        </Box>) : (<NotAModal />)}
                </SearchComponentStoreProvider>
            </Box>
            {isOpen && (<Box position="absolute" backgroundColor="navy.900" opacity=".3" height="100vh" width="100vw" top="0" bottom="0" left="0" right="0" zIndex="layer4" // 1 more than css .SpotMap - .Search-map-view .SpotList, .ResponsiveSearchLayout-zone-2 .SpotList
        />)}
        </Flex>);
};
