import React from 'react';
import { Box, Icon, Text, Button } from '@spothero/ui';
import { SearchComponent } from '@spothero/search-component';
import X from '@spothero/icons/x';
import { VenueEventEdit } from '../../events/edit/VenueEventEdit';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchComponentEventView, setSearchComponentIsOpen, } from 'store/search/search-actions';
import { updateSearchComponentKey } from 'store/search-request/search-request-actions';
export const NotAModal = () => {
    const eventView = useSelector((state) => state.search.data.searchComponentEventView);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setSearchComponentIsOpen(false));
        dispatch(setSearchComponentEventView(null));
        dispatch(updateSearchComponentKey());
    };
    const getLabel = () => {
        if (eventView === 'select')
            return 'Change Event';
        if (eventView === 'selectWFilter')
            return 'Events';
        return 'Edit';
    };
    const label = getLabel();
    return (<Box paddingX="4">
            <Box paddingBottom="5">
                <Text width="100%" textAlign="center" position="relative" fontWeight="medium" fontSize="sm">
                    <Button variant="tertiary" color="black" onClick={handleClose} position="absolute" left="0" height="100%" data-testid="NotAModal-close-button">
                        <Icon as={X} height="4.5" width="4.5"/>
                    </Button>
                    {label}
                </Text>
            </Box>
            {eventView ? <VenueEventEdit /> : <SearchComponent />}
        </Box>);
};
