import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
export const POWER_BOOKING_SOURCE = {
    monthly: 'monthly_midfunnel',
    transient: 'transient_midfunnel',
    monthly_homepage: 'monthly_homepage',
    transient_homepage: 'transient_homepage',
};
/**
 * ### trackPowerBookingOpened
 * Sends event 'Power Booking Opened' to Segment.
 * Triggered when user opens Power Booking modal
 *
 * @function trackPowerBookingOpened
 * @param {string} source
 * - Source can be one of options "monthly_search", "transient_search", "transient_midfunnel" or "monthly_midfunnel".
 * @returns {void}
 */
export default function trackPowerBookingOpened({ source }) {
    try {
        SegmentUtils.track({
            event: 'Power Booking Opened',
            properties: {
                source,
            },
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackPowerBookingOpened',
        });
    }
}
