import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
/**
 * ### trackSearchEditButtonClicked
 * Sends event 'Search Edit Button Clicked' to Segment.
 * @param parkingType
 * @param isMobile
 */
export const trackSearchEditButtonClicked = ({ parkingType, isMobile, }) => {
    try {
        SegmentUtils.track({
            event: 'Search Edit Button Clicked',
            properties: {
                /* eslint-disable camelcase */
                parking_type: parkingType,
                client: isMobile ? 'mobile-web' : 'web',
                /* eslint-enable camelcase */
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackSearchEditButtonClicked',
        });
    }
};
