import { useDispatch, useSelector } from 'react-redux';
import UrlUtils from '@spothero/utils/url';
import { useEffect } from 'react';
import { setSearchPageView } from 'store/search/search-actions';
import { SearchPageView } from 'utils/search-page-view';
export const useShowEventsData = () => {
    const dispatch = useDispatch();
    const { hide_event_modal: hideEventModal } = UrlUtils.parseParams(window.location.search);
    const searchType = useSelector((state) => state.search.data.searchType);
    const canHaveEvents = useSelector((state) => state.destination.data.can_have_events);
    const showSEMEvent = searchType === 'venue' && !hideEventModal && canHaveEvents;
    useEffect(() => {
        // This is a workaround to ensure that the SEM event landing page is displayed correctly
        // On mobile map view, it gets clipped by the map, therefore we switch to list view
        if (showSEMEvent) {
            dispatch(setSearchPageView({
                view: SearchPageView.MOBILE_LIST,
                isTrackingEnabled: false,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { showSEMEvent, searchType, hideEventModal, canHaveEvents };
};
