import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
export default function trackFindParkingNearMeClicked() {
    try {
        SegmentUtils.track({
            event: 'Find Parking Near Me Clicked',
            properties: {},
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackFindParkingNearMeClicked',
        });
    }
}
