import React, { useEffect } from 'react';
import { UpcomingEvents } from './UpcomingEvents';
import { Box, Heading } from '@spothero/ui';
import { useSelector } from 'react-redux';
import StorageUtils from '@spothero/utils/storage';
import SegmentUtils from 'utils/segment';
export const VenueEventEdit = () => {
    const title = useSelector((state) => state.destination.data.title);
    const searchString = useSelector((state) => state.searchRequest.search_string);
    useEffect(() => {
        const userLatLng = StorageUtils.get('sh-user-location', 'session');
        SegmentUtils.track({
            event: 'Event List Display',
            properties: {
                /* eslint-disable camelcase */
                ...(userLatLng && { user_location: userLatLng }),
                ...(searchString && { search_query: searchString }),
                destination: title,
                /* eslint-enable camelcase */
            },
        });
    }, [searchString, title]);
    return (
    // Height comes from header height plus height of the x and events
    <Box overflow="auto" height={'calc(100vh - 90px - 41px)'} data-testid="VenueEventEdit">
            <Heading as="h3" paddingBottom="4">
                Upcoming Events at {title}
            </Heading>
            <UpcomingEvents />
        </Box>);
};
