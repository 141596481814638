import { HEADER_SET_TITLE } from 'store/header/header-actions';
const initialState = {
    data: {
        headerTitle: null,
    },
};
export default function headerReducer(state = initialState, { type, payload }) {
    switch (type) {
        case HEADER_SET_TITLE:
            return {
                data: {
                    headerTitle: payload,
                },
            };
        default:
            return state;
    }
}
