import {getWindow} from 'ssr-window';
import SegmentUtils from 'utils/segment';

const window = getWindow();

export default function trackSearchLanding(
    referrerAffiliate,
    destinationCategory
) {
    const properties = {
        referrer_affiliate: referrerAffiliate,
        user_facing_url: window.location.href,
        ...(destinationCategory && {destination_category: destinationCategory}),
    };

    SegmentUtils.page({
        name: 'Search Landing',
        properties,
    });
}
