import dayjs from 'utils/dayjs';
export const mapParkingTypes = (parkingType, powerBookingSource) => {
    if (parkingType === 'monthly') {
        return {
            parkingType: 'monthly',
            parkingSubType: 'monthly',
        };
    }
    else if (parkingType === 'power_booking') {
        return {
            parkingType: powerBookingSource.startsWith('transient')
                ? 'hourly'
                : 'monthly',
            parkingSubType: 'power_booking',
        };
    }
    else if (parkingType === 'airport') {
        return {
            parkingType: 'airport',
            parkingSubType: 'airport',
        };
    }
    else if (parkingType === 'event') {
        return {
            parkingType: 'event',
            parkingSubType: 'event',
        };
    }
    else if (parkingType === 'event_package') {
        throw new Error(`Unsupported parking type: ${parkingType}`);
    }
    return {
        parkingType: 'hourly',
        parkingSubType: 'hourly',
    };
};
export const _mapDates = (params) => {
    const { parkingSubType, starts, ends, powerBookingPeriods } = params;
    if (parkingSubType === 'hourly' ||
        parkingSubType === 'event' ||
        parkingSubType === 'airport') {
        return {
            startDate: starts,
            endDate: ends,
        };
    }
    else if (parkingSubType === 'monthly') {
        return {
            monthlyStartDate: starts,
        };
    }
    else if (parkingSubType === 'power_booking') {
        return {
            startDate: undefined,
            endDate: undefined,
            powerBookingDates: powerBookingPeriods.map(pb => dayjs(pb.starts).toDate()),
            powerBookingStartTime: dayjs(powerBookingPeriods[0].starts).format('HH:mm:ss'),
            powerBookingEndTime: dayjs(powerBookingPeriods[0].ends).format('HH:mm:ss'),
        };
    }
    return {};
};
export const mapReduxSearchStateToSearchComponentState = (params) => {
    const { parkingType, starts, ends, powerBookingPeriods, powerBookingSource, } = params;
    const { parkingType: scParkingType, parkingSubType } = mapParkingTypes(parkingType, powerBookingSource);
    return {
        parkingType: scParkingType,
        parkingSubType,
        ..._mapDates({
            parkingSubType,
            starts,
            ends,
            powerBookingPeriods,
            powerBookingSource,
        }),
    };
};
