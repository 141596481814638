import React from 'react';
import { Box, Text, Icon } from '@spothero/ui';
import { GenericLineItem } from '../event-line-item/GenericLineItem';
import { GenericListItem } from '../event-list-item/GenericListItem';
import IconCalendar from '@spothero/icons/calendar';
import IconClock from '@spothero/icons/clock';
import { useDispatch, useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
import SegmentUtils from 'utils/segment';
import { routeToSearch } from 'router/router-utils';
import { push } from 'store/router/router-actions';
import { Page } from 'utils/page-utils';
import { getRouteParam, paramNameOptions } from 'utils/url-utils';
export const NotEventIcon = ({ IconType }) => {
    const parkingType = useSelector(getParkingType);
    return (<Box className="event-date" // Used for hover state
     minWidth="36px" width="36px" height="38px" rounded="md" overflow="hidden" backgroundColor="gray.light" display="flex" flexDirection="column" justifyContent="center" alignItems="center" transform={parkingType === 'event' ? undefined : 'scale(0.85)'}>
            <Icon as={IconType} height="5" width="5"/>
        </Box>);
};
export const NotGoingToEvent = () => {
    const title = useSelector((state) => state.destination.data.title);
    const destinationId = useSelector((state) => state.destination.data.id);
    // const destinationId = useSelector(
    //     (state: RootState) => state.destination.data.
    // );
    const dispatch = useDispatch();
    const pushTo = (...args) => dispatch(push(...args));
    const onViewClickWithSegment = (isMonthly = false) => {
        SegmentUtils.track({
            event: 'Find Non-Event Parking Clicked',
            properties: {
                destination_name: title,
                destination_id: destinationId,
            },
        });
        routeToSearch({
            method: pushTo,
            queryParams: {
                id: destinationId,
                kind: Page.DESTINATION,
                view: getRouteParam(paramNameOptions.VIEW),
                hide_event_modal: true,
                ...(isMonthly && { monthly: isMonthly }),
            },
        });
    };
    const transientClick = () => onViewClickWithSegment(false);
    const monthlyClick = () => onViewClickWithSegment(true);
    return (<Box data-testid="NotGoingToEvent" paddingTop={4}>
            <Text fontSize="sm" fontWeight="medium">
                Not going to an event?
            </Text>
            <GenericListItem handleClick={transientClick} textTransform="none">
                <GenericLineItem topText="Find hourly or daily parking" bottomText={`Short-term parking near ${title}`}>
                    <NotEventIcon IconType={IconClock}/>
                </GenericLineItem>
            </GenericListItem>
            <GenericListItem handleClick={monthlyClick} textTransform="none" sx={{ '&::after': {} }} // Cancel border
    >
                <GenericLineItem topText="Find monthly parking" bottomText={`Monthly parking near ${title}`}>
                    <NotEventIcon IconType={IconCalendar}/>
                </GenericLineItem>
            </GenericListItem>
        </Box>);
};
