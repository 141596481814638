export const fetchPlaceCoordinates = ({ placeId, address, location, googleMapsLibraries, }) => {
    const geocodeParams = {
        placeId,
        address,
        location: undefined,
    };
    if (location) {
        geocodeParams.location = {
            lat: location.latitude,
            lng: location.longitude,
            placeId,
        };
    }
    // When we already have lat/lng and place id, return them immediately
    if (location && location.latitude && location.longitude && placeId) {
        return Promise.resolve({
            latitude: location.latitude,
            longitude: location.longitude,
            placeId,
        });
    }
    return new Promise((resolve, reject) => {
        const geocoder = new googleMapsLibraries.geocoding.Geocoder();
        geocoder.geocode(geocodeParams, (results, status) => {
            if (status === googleMapsLibraries.geocoding.GeocoderStatus.OK &&
                results[0]) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                // prefer the placeId passed in to avoid any potential mismatch between the placeId and the geocoded placeId
                const geocodedPlaceId = placeId || results[0].place_id;
                resolve({ latitude, longitude, placeId: geocodedPlaceId });
            }
            else {
                reject(status);
            }
        });
    });
};
