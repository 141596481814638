import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import dayjs from 'utils/dayjs-timezone';
import TimeUtils from 'utils/time';
import { updateTimes } from 'store/search-request/search-request-actions';
export const useHandlePastTimes = () => {
    const dispatch = useDispatch();
    const { parkingType, timezone, startsDate, endsDate, starts: reduxStarts, ends: reduxEnds, } = useReduxSearchState();
    useEffect(() => {
        const now = dayjs().tz(timezone).set('second', 0).set('millisecond', 0);
        const minDateTime = now.get('minute') < 30
            ? now.set('minute', 0)
            : now.set('minute', 30);
        const starts = dayjs(startsDate).tz(timezone);
        const isStartInPast = starts.isBefore(minDateTime);
        const ends = endsDate ? dayjs(endsDate).tz(timezone) : undefined;
        const isEndInPast = ends?.isBefore(minDateTime) || false;
        if (isStartInPast || isEndInPast) {
            dispatch(updateTimes({
                starts: isStartInPast
                    ? minDateTime.format(TimeUtils.V1_API_TIMESTAMP_FORMAT)
                    : reduxStarts,
                ends: isEndInPast
                    ? minDateTime
                        .add(30, 'minute')
                        .format(TimeUtils.V1_API_TIMESTAMP_FORMAT)
                    : reduxEnds,
                isMonthly: parkingType === 'monthly',
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
