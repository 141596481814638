import React, { useState } from 'react';
import { Box, Icon, Flex, Text } from '@spothero/ui';
import { GenericListItem } from '../event-list-item/GenericListItem';
import TicketEvent from '@spothero/icons/ticket-event';
import { useSelector } from 'react-redux';
import { getParkingType } from 'store/selectors/selectors';
function ShowMore({ children, pageSize = 3, ...props }) {
    const [currentPageSize, setCurrentPageSize] = useState(pageSize);
    const parkingType = useSelector(getParkingType);
    if (!Array.isArray(children))
        return null; // Early return to validate children exist
    if (children[0]?.props === undefined)
        return null; // Early return to ensure react element is passed, not string
    const isShowMoreVisible = currentPageSize < children.length;
    const filteredChildren = children.slice(0, currentPageSize);
    return (<Flex flexDirection="column" data-testid="ShowMore">
            <Box>{filteredChildren}</Box>
            {isShowMoreVisible && (<GenericListItem handleClick={() => setCurrentPageSize(children.length)} asAnchor={false} sx={{ '&::after': {} }} // Cancel border
         {...props}>
                    <Box className="event-date" // Used for hover state
         minWidth="36px" width="36px" height="38px" marginRight="2" rounded="md" overflow="hidden" backgroundColor="blue.100" display="flex" flexDirection="column" justifyContent="center" alignItems="center" transform={parkingType === 'event' ? undefined : 'scale(0.85)'}>
                        <Icon as={TicketEvent} fill="primary.default" height="5" width="5"/>
                    </Box>
                    <Text fontSize="sm" fontWeight="semibold" color="primary.default">
                        More Upcoming Events
                    </Text>
                </GenericListItem>)}
        </Flex>);
}
export default ShowMore;
