import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
/**
 * Tracks an error message displayed event in Segment
 */
export default function trackErrorMessageDisplayed({ message, action = null, screen = null, errorMessage = null, errorHeader = null, currentScreenName = null, city = null, pageType = null, parkingType = null, query = null, searchId = null, }) {
    const toObjectWhenTruthy = (key, value) => value ? { [key]: value } : {};
    try {
        SegmentUtils.track({
            event: 'Error Message Displayed',
            properties: {
                'error message': message,
                ...toObjectWhenTruthy('error_message', errorMessage),
                ...toObjectWhenTruthy('error action', action),
                ...toObjectWhenTruthy('error screen', screen),
                ...toObjectWhenTruthy('error_header', errorHeader),
                ...toObjectWhenTruthy('current_screen_name', currentScreenName),
                ...toObjectWhenTruthy('city', city),
                ...toObjectWhenTruthy('page_type', pageType),
                ...toObjectWhenTruthy('parking_type', parkingType),
                ...toObjectWhenTruthy('query', query),
                ...toObjectWhenTruthy('search_id', searchId),
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackErrorMessageDisplayed',
        });
    }
}
