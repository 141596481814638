import { useSelector } from 'react-redux';
import { getParkingType, getSearchVehicle } from 'store/selectors/selectors';
import dayjs from 'utils/dayjs-timezone';
import { getSpotsV2 } from 'store/spots/spots-selectors';
import { Page } from 'utils/page-utils';
import { clampTimeToHalfHourIntervals } from 'common/search-controls/date-time-pickers/SearchDateTimePickers';
const getStartsDate = (state) => {
    const starts = dayjs.tz(state.searchRequest.starts, state.city.data.timezone);
    const clamped = clampTimeToHalfHourIntervals({
        time: starts,
        mode: 'start',
    });
    return clamped.toDate();
};
const getEndsDate = (state) => {
    if (!state.searchRequest.ends) {
        return;
    }
    const ends = dayjs.tz(state.searchRequest.ends, state.city.data.timezone);
    const clamped = clampTimeToHalfHourIntervals({
        time: ends,
        mode: 'end',
    });
    return clamped.toDate();
};
export const useReduxSearchState = () => {
    const city = useSelector((state) => state.city.data);
    const event = useSelector((state) => state.event.data);
    const destination = useSelector((state) => state.destination.data);
    const originalCitySlug = useSelector((state) => state.city.data.slug);
    const timezone = useSelector((state) => state.city.data.timezone);
    const parkingType = useSelector(getParkingType);
    const starts = useSelector((state) => state.searchRequest.starts);
    const ends = useSelector((state) => state.searchRequest.ends);
    const startsDate = useSelector(getStartsDate);
    const endsDate = useSelector(getEndsDate);
    const powerBookingPeriods = useSelector((state) => state.searchRequest.powerBookingPeriods);
    const powerBookingSource = useSelector((state) => state.searchRequest.powerBookingSource);
    const searchRequestId = useSelector((state) => state.searchRequest.id);
    const selectedSpotId = useSelector((state) => state.spots.selectedSpotId);
    const activeSearchTitle = useSelector((state) => state.search.data.breadcrumbs.activeBreadcrumb.label);
    const searchType = useSelector((state) => state.search.data.searchType);
    const isFetchComplete = useSelector((state) => !state.spots.isPending);
    const numActiveFilters = useSelector((state) => state.filters.numActive);
    const mobilePaginationLimit = useSelector((state) => state.search.data.mobilePaginationLimit);
    const spotsV2 = useSelector(getSpotsV2);
    const sortBy = useSelector((state) => state.search.data.sortBy);
    const view = useSelector((state) => state.search.data.view);
    const isPending = useSelector((state) => state.spots.isPending);
    const isPowerBooking = useSelector((state) => state.searchRequest.powerBooking);
    const vehicle = useSelector(getSearchVehicle);
    const originalLatitude = useSelector((state) => state.searchRequest.latitude);
    const originalLongitude = useSelector((state) => state.searchRequest.longitude);
    const events = useSelector((state) => state.destination.data.events);
    const priceBreakdownModalSpotId = useSelector((state) => state.search.data.priceBreakdownModalSpotId);
    const priceBreakdownModalSpotRate = useSelector((state) => state.search.data.priceBreakdownModalSpotRate);
    const airports = useSelector((state) => state.search.data.airports);
    const originalPageType = useSelector((state) => state.searchRequest.pageType);
    const originalSearchString = useSelector((state) => state.searchRequest.search_string);
    const originalId = originalPageType === Page.CITY
        ? city.id
        : originalPageType === Page.DESTINATION ||
            originalPageType === Page.VENUE
            ? destination.id
            : null;
    return {
        city: city,
        destination,
        originalCitySlug,
        originalLatitude,
        originalLongitude,
        originalPageType,
        originalId,
        originalSearchString,
        parkingType,
        timezone,
        starts,
        ends,
        startsDate,
        endsDate,
        powerBookingPeriods,
        powerBookingSource,
        searchRequestId,
        selectedSpotId,
        activeSearchTitle,
        searchType,
        isFetchComplete,
        numActiveFilters,
        mobilePaginationLimit,
        spotsV2,
        sortBy,
        view,
        isPending,
        isPowerBooking,
        vehicle,
        event,
        events,
        priceBreakdownModalSpotId,
        priceBreakdownModalSpotRate,
        airports,
    };
};
