import SearchAPI from 'api/search';
import { Page } from 'utils/page-utils';
import { routeToSearch } from 'router/router-utils';
import { push } from 'store/router/router-actions';
import CityAPI from 'api/city';
import { getRouteParam, getUrlParamAsObject, paramNameOptions, } from 'utils/url-utils';
import { fetchPlaceCoordinates } from './place-utils';
import { setTermsAndUpdatePowerBookingTimes, updateTimes, } from 'store/search-request/search-request-actions';
import DestinationAPI from 'api/destination';
import ErrorUtils from 'utils/error-utils';
export const getAirportCodeByDestinationId = async (id) => {
    try {
        const destination = await DestinationAPI.get(id.toString()).then(res => res.data.data);
        const { airport: { iata_code }, } = destination;
        return iata_code || '';
    }
    catch (error) {
        ErrorUtils.sendSentryException(error);
        return '';
    }
};
export const _getAddress = (selectedSuggestion) => {
    // The address is used in the Google Geocoding API.
    // Destination and Current Location we use in Search-Component are not valid addresses.
    if (selectedSuggestion.description === 'Destination' ||
        selectedSuggestion.description === 'Current Location' ||
        selectedSuggestion.id // event
    ) {
        return undefined;
    }
    return selectedSuggestion.description;
};
export const handleSearchUpdates = async ({ destination, event, dateTimeValues, selectedSuggestion, parkingSubType, originalCitySlug, originalLatitude, originalLongitude, originalPageType, originalParkingSubType, originalId, originalSearchString, timezone, dispatch, history, googleMapsLibraries, onClose, }) => {
    const { starts, ends, powerBookingPeriods, powerBookingSource, } = dateTimeValues;
    const address = _getAddress(selectedSuggestion);
    const suggestionLatLng = await fetchPlaceCoordinates({
        placeId: selectedSuggestion.placeId,
        address,
        location: selectedSuggestion?.location,
        googleMapsLibraries,
    });
    const isMonthly = parkingSubType === 'monthly';
    const isPowerBooking = parkingSubType === 'power_booking';
    const googlePlaceId = selectedSuggestion.placeId || suggestionLatLng.placeId;
    const params = {
        latitude: suggestionLatLng.latitude,
        longitude: suggestionLatLng.longitude,
        search_string: address,
        google_places_id: googlePlaceId,
        google_places_place_id: googlePlaceId,
        monthly: parkingSubType === 'monthly',
    };
    const { data: { data: searchParams }, } = await SearchAPI.cleanParams(params);
    const { page_info: { page_type: newPageType, setup: { city: { id: cityId, slug: citySlug } = {}, destination: { id: destinationId, is_airport } = {
        is_airport: false,
    }, }, }, } = searchParams;
    const hasParkingChanged = parkingSubType !== originalParkingSubType;
    let hasSearchChanged = originalLatitude !== searchParams.latitude ||
        originalLongitude !== searchParams.longitude ||
        hasParkingChanged;
    let pageType = hasSearchChanged ? newPageType : originalPageType;
    // handle event search updates
    if (!hasParkingChanged && originalParkingSubType === 'event') {
        pageType = Page.EVENT;
        const eventSuggestion = selectedSuggestion;
        // recalculate hasSearchChanged for event search
        hasSearchChanged =
            eventSuggestion &&
                eventSuggestion?.id &&
                event &&
                event?.id &&
                eventSuggestion.id !== event.id;
    }
    let id = hasSearchChanged
        ? pageType === Page.CITY
            ? cityId
            : pageType === Page.DESTINATION || pageType === Page.VENUE
                ? (destinationId || destination?.id)
                : null
        : originalId;
    if (parkingSubType === 'event') {
        id = event?.id;
    }
    const searchString = hasSearchChanged
        ? selectedSuggestion.description
        : originalSearchString;
    const latitude = hasSearchChanged
        ? suggestionLatLng.latitude
        : originalLatitude;
    const longitude = hasSearchChanged
        ? suggestionLatLng.longitude
        : originalLongitude;
    const defaultParams = id
        ? { ...getUrlParamAsObject('operator_id') } // preserve operator_id
        : {
            latitude,
            longitude,
            search_string: searchString,
            ...getUrlParamAsObject('operator_id'),
        };
    let airportParams = {};
    if (is_airport) {
        const airportCode = await getAirportCodeByDestinationId(id);
        if (airportCode) {
            airportParams = {
                kind: 'airport',
                id: airportCode,
                airport: true,
                hide_modal: true,
                latitude: null,
                longitude: null,
                search_string: null,
            };
        }
    }
    const handlePowerBookingSearch = () => {
        const queryStarts = [];
        const queryEnds = [];
        powerBookingPeriods?.forEach(({ starts: periodStarts, ends: periodEnds }) => {
            queryStarts.push(periodStarts);
            queryEnds.push(periodEnds);
        });
        dispatch(setTermsAndUpdatePowerBookingTimes({
            // Why is city and destination undefined here: https://github.com/spothero/consumer-web/blob/3a7e12810b9cf386af77fe3dc92a80470148d565/src/js/store/search/search-reducer.ts#L215
            // The city and destination data is used only in one place to calculate zoom level for map.
            // But there is no usage of this zoom data anywhere in the code.
            // Therefore, it is safe to pass undefined for city and destination.
            city: undefined,
            destination: undefined,
            powerBookingPeriods,
            powerBookingSource,
        }));
        return routeToSearch({
            method: (...a) => {
                onClose?.();
                dispatch(push(...a));
            },
            pageType,
            id,
            queryParams: {
                ...defaultParams,
                starts: queryStarts,
                ends: queryEnds,
                power_booking: true,
                ...(powerBookingSource && {
                    pb_source: powerBookingSource,
                }),
                ...getUrlParamAsObject('hide_event_modal'),
            },
            historyState: { searchParams },
        });
    };
    const handleAnyTimezoneChange = async () => {
        const { data: { data: { timezone: newTimezone }, }, } = await CityAPI.get(citySlug);
        if (timezone && timezone !== newTimezone) {
            routeToSearch({
                method: (url) => {
                    window.location.replace(url);
                },
                pageType,
                id,
                queryParams: {
                    ...(isMonthly && { monthly: true }),
                    ...defaultParams,
                    starts: null,
                    ends: null,
                    ...airportParams,
                    view: getRouteParam(paramNameOptions.VIEW),
                },
                historyState: { searchParams },
            });
            return true;
        }
        return false;
    };
    // catchall search
    const handleCatchallSearch = () => {
        routeToSearch({
            method: (url) => {
                window.location.replace(url);
            },
            pageType,
            id,
            queryParams: {
                ...(isMonthly && { monthly: true }),
                ...defaultParams,
                starts,
                ends: isMonthly ? null : ends,
                ...airportParams,
                view: getRouteParam(paramNameOptions.VIEW),
                ...getUrlParamAsObject('hide_event_modal'),
            },
            historyState: { searchParams },
        });
    };
    const handleSearchLocationUnchanged = () => {
        onClose?.();
        dispatch(updateTimes({
            starts,
            ends,
            isMonthly,
        }));
        // update URL params
        routeToSearch({
            method: (url) => {
                history.replace(url, {
                    reload: false,
                });
            },
            pageType,
            id,
            queryParams: {
                ...(isMonthly && { monthly: true }),
                ...defaultParams,
                starts,
                ends: isMonthly ? null : ends,
                ...airportParams,
                view: getRouteParam(paramNameOptions.VIEW),
                ...getUrlParamAsObject('hide_event_modal'),
            },
            historyState: { searchParams },
        });
    };
    // handle power booking
    if (isPowerBooking) {
        return handlePowerBookingSearch();
    }
    // Avoid full page refresh when possible
    if (!hasSearchChanged && originalParkingSubType !== 'power_booking') {
        return handleSearchLocationUnchanged();
    }
    // handle timezone change
    if (originalCitySlug && originalCitySlug !== citySlug) {
        const hasTimeZoneChanged = await handleAnyTimezoneChange();
        if (hasTimeZoneChanged) {
            return; // timezone change already handled
        }
    }
    handleCatchallSearch();
};
