import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
import dayjs from 'utils/dayjs-timezone';
import { getDefaultTimezone } from 'segment/utils/time-utils';
const getDateType = (parkingSubType) => {
    if (parkingSubType === 'power_booking') {
        return 'PowerBooking';
    }
    else if (parkingSubType === 'monthly') {
        return 'Monthly';
    }
    else if (parkingSubType === 'hourly') {
        return 'HourlyDaily';
    }
    throw new Error(`Invalid parkingSubType: ${parkingSubType}`);
};
/**
 * Calculates the time difference between a given start and end time.
 *
 * @function calculateTimeDifference
 * @param {{starts: string, ends: string, unit: string, isFloatingPointNumber: boolean}} args - The function arguments object
 * @param {string} starts - The ISO string for the start time
 * @param {string} ends - The ISO string for the end time
 * @param {string} [unit='hour'] - The unit to display the difference in (ie. hours, minutes, days, etc.) defaults to `'hour'` {@link https://day.js.org/docs/en/display/difference|More information on dayjs.diff()}
 * @param {boolean} [isFloatingPointNumber=true] - A boolean to determine if the returned time difference is a floting point number or an integer, defaults to `true`
 * @returns {number} The time difference between the start and end times
 */
export function calculateTimeDifference({ starts, ends, unit = 'hour', isFloatingPointNumber = true, }) {
    const startDateObj = dayjs(starts);
    const endDateObj = dayjs(ends);
    return endDateObj.diff(startDateObj, unit, isFloatingPointNumber) || 0;
}
/**
 * Combines a date and a time into a single date object
 *
 * @param {Dayjs|Date} date - The date object
 * @param {Dayjs|Date|string} time - The time string in HH:mm format or a date object
 * @param {string} timezone - The timezone to use
 */
export const combineDateTime = (date, time, timezone = null) => {
    let hours;
    let minutes;
    if (typeof time === 'string') {
        [hours, minutes] = time.split(':').map(Number);
    }
    else {
        const timeDj = timezone ? dayjs(time).tz(timezone) : dayjs(time);
        hours = timeDj.get('hour');
        minutes = timeDj.get('minute');
    }
    if (timezone) {
        return dayjs(date)
            .tz(timezone)
            .set('hour', hours)
            .set('minute', minutes)
            .set('second', 0);
    }
    return dayjs(date)
        .set('hour', hours)
        .set('minute', minutes)
        .set('second', 0);
};
const getPowerBookingProperties = (params) => {
    const defaultTimezone = getDefaultTimezone();
    const startDate = params.powerBookingDates[0];
    const endDate = params.powerBookingDates[params.powerBookingDates.length - 1];
    return {
        search_start_time_utc: combineDateTime(dayjs(startDate), params.powerBookingStartTime).toISOString(),
        search_end_time_utc: combineDateTime(dayjs(endDate), params.powerBookingEndTime).toISOString(),
        search_length: calculateTimeDifference({
            starts: startDate,
            ends: endDate,
        }),
        search_lead_time: calculateTimeDifference({
            // Setting timezones here allows for normalization of time differences across timezones
            starts: dayjs().tz(defaultTimezone),
            ends: dayjs(startDate).tz(defaultTimezone, true),
            unit: 'minute',
        }),
        days_selected: params.powerBookingDates.map(date => ({
            search_start_time_utc: combineDateTime(date, params.powerBookingStartTime).toISOString(),
            search_end_time_utc: combineDateTime(date, params.powerBookingEndTime).toISOString(),
        })),
        date_type: getDateType(params.parkingSubType),
        default_timezone: defaultTimezone,
        default_search_length: null,
        default_search_end_time_utc: null,
        default_search_start_time_utc: null,
        search_start_time_diff_from_default: null,
        search_end_time_diff_from_default: null,
    };
};
const getDateRangeProperties = (params) => {
    const defaultTimezone = getDefaultTimezone();
    return {
        search_start_time_utc: dayjs(params.dateRangeStartDate).toISOString(),
        search_end_time_utc: dayjs(params.dateRangeEndDate).toISOString(),
        search_length: calculateTimeDifference({
            starts: params.dateRangeStartDate,
            ends: params.dateRangeEndDate,
        }),
        search_lead_time: calculateTimeDifference({
            // Setting timezones here allows for normalization of time differences across timezones
            starts: dayjs().tz(defaultTimezone),
            ends: dayjs(params.dateRangeStartDate).tz(defaultTimezone, true),
            unit: 'minute',
        }),
        days_selected: [],
        date_type: getDateType(params.parkingSubType),
        default_timezone: defaultTimezone,
        default_search_length: calculateTimeDifference({
            starts: params.originalDateRangeStartDate,
            ends: params.originalDateRangeEndDate,
        }),
        default_search_end_time_utc: dayjs(params.originalDateRangeEndDate).toISOString(),
        default_search_start_time_utc: dayjs(params.originalDateRangeStartDate).toISOString(),
        search_start_time_diff_from_default: calculateTimeDifference({
            starts: params.originalDateRangeStartDate,
            ends: params.dateRangeStartDate,
            unit: 'minute',
        }),
        search_end_time_diff_from_default: calculateTimeDifference({
            starts: params.originalDateRangeEndDate,
            ends: params.dateRangeEndDate,
            unit: 'minute',
        }),
    };
};
const getMonthlyProperties = (params) => {
    const defaultTimezone = getDefaultTimezone();
    return {
        search_start_time_utc: dayjs(params.monthlyStartDate).toISOString(),
        search_end_time_utc: null,
        search_length: null,
        search_lead_time: calculateTimeDifference({
            // Setting timezones here allows for normalization of time differences across timezones
            starts: dayjs().tz(defaultTimezone),
            ends: dayjs(params.monthlyStartDate).tz(defaultTimezone, true),
            unit: 'minute',
        }),
        days_selected: [],
        date_type: getDateType(params.parkingSubType),
        default_timezone: defaultTimezone,
        default_search_length: null,
        default_search_end_time_utc: null,
        default_search_start_time_utc: dayjs(params.originalMonthlyStartDate).toISOString(),
        search_start_time_diff_from_default: calculateTimeDifference({
            starts: params.originalMonthlyStartDate,
            ends: params.monthlyStartDate,
            unit: 'minute',
        }),
        search_end_time_diff_from_default: null,
    };
};
export default function trackDateEntered(params) {
    try {
        const { parkingSubType } = params;
        let properties = {};
        if (parkingSubType === 'power_booking') {
            properties = getPowerBookingProperties(params);
        }
        else if (parkingSubType === 'monthly') {
            properties = getMonthlyProperties(params);
        }
        else if (parkingSubType === 'hourly') {
            properties = getDateRangeProperties(params);
        }
        SegmentUtils.track({
            event: 'Date Entered',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 1,
                    },
                },
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackDateEntered',
        });
    }
}
