import React from 'react';
import { Box } from '@spothero/ui';
import SpotDetailsTransient from 'pages/search-transient-event-monthly/components/spot-details-transient';
import SpotDetailsMonthlyResponsive from 'pages/search-transient-event-monthly/components/spot-details-monthly/SpotDetailsMonthlyResponsive';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { useHistory } from 'react-router';
import { push } from 'store/router/router-actions';
import { useDispatch } from 'react-redux';
import useIsMobile from 'hooks/use-is-mobile';
import { SearchPageView } from 'utils/search-page-view';
export const SpotDetails = () => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedSpotId: spotId, parkingType, view } = useReduxSearchState();
    const SpotDetailsComponent = parkingType === 'monthly'
        ? SpotDetailsMonthlyResponsive
        : SpotDetailsTransient;
    const onSpotDetailsHidden = () => {
        const queryParams = new URLSearchParams(history.location.search);
        queryParams.delete('spot-id');
        dispatch(push(`${history.location.pathname}?${queryParams.toString()}`));
    };
    const onEditTimesModalClose = () => { };
    return (<Box data-testid="SpotDetailsContainer" sx={{
            '& > .SpotDetails': {
                zIndex: view === SearchPageView.MOBILE_LIST
                    ? 'unset'
                    : 'layer3',
            },
            '& .SpotDetailsMonthlyResponsive-close': {
                zIndex: 'layer3',
                boxShadow: 'unset',
            },
        }}>
            <SpotDetailsComponent isMobile={isMobile} spotId={spotId} onCloseClick={onSpotDetailsHidden} onEditTimesModalClose={onEditTimesModalClose}/>
        </Box>);
};
