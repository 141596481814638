import React from 'react';
import { Button } from '@spothero/ui';
import { styles } from '../styles';
export const FilterCheckboxBase = ({ children, onClick, isActive, testid, }) => {
    return (<Button {...styles} _hover={{
            borderColor: 'black',
        }} _focus={{
            boxShadow: 'none',
            borderColor: isActive ? 'black' : 'accent',
        }} _active={{
            borderColor: isActive ? 'black' : 'accent',
        }} backgroundColor={isActive ? 'gray.light' : 'transparent'} borderColor={isActive ? 'black' : 'accent'} data-testid={testid} onClick={onClick} key={`FilterCheckboxBase-${isActive ? 'active' : 'inactive'}`}>
            {children}
        </Button>);
};
