import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
export const useDefaultSuggestion = () => {
    const { city: { data: city }, destination: { data: destination }, event: { data: event }, search: { data: { searchType, destinationTitle }, }, searchRequest: { starts, ends, latitude, longitude }, } = useSelector((state) => state);
    let defaultSuggestion;
    switch (searchType) {
        case 'city':
            defaultSuggestion = {
                description: `${city.city}, ${city.state}`,
                location: {
                    latitude: city.latitude,
                    longitude: city.longitude,
                },
            };
            break;
        case 'event':
            defaultSuggestion = {
                eventTitle: event.title,
                description: `${destination.title}, ${destination.city}, ${destination.state}`,
                id: event.id,
                parkingWindow: {
                    ends: dayjs
                        .tz(event.parking_window.ends, destination.timezone)
                        .format(),
                    starts: dayjs
                        .tz(event.parking_window.starts, destination.timezone)
                        .format(),
                },
                starts: dayjs.tz(starts, destination.timezone).format(),
                ends: dayjs.tz(ends, destination.timezone).format(),
                eventStart: dayjs.tz(event.starts, 'UTC').format(),
                eventEnd: dayjs.tz(event.ends, 'UTC').format(),
                location: {
                    latitude: latitude,
                    longitude: longitude,
                },
            };
            break;
        case 'destination':
        case 'venue':
            defaultSuggestion = {
                description: `${destination.title}, ${destination.city}, ${destination.state}`,
                location: {
                    latitude: destination.latitude,
                    longitude: destination.longitude,
                },
            };
            break;
        case 'address':
            defaultSuggestion = {
                description: destinationTitle,
                location: {
                    latitude: latitude,
                    longitude: longitude,
                },
            };
            break;
        default:
            defaultSuggestion = undefined;
            break;
    }
    return defaultSuggestion;
};
