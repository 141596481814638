import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { setHeaderTitle } from 'store/header/header-actions';
export const useHeaderSync = () => {
    const dispatch = useDispatch();
    const { parkingType, destination } = useReduxSearchState();
    useEffect(() => {
        const eventDestinationTitle = parkingType === 'event' && destination?.title
            ? `${destination.title} Event Parking`
            : null;
        dispatch(setHeaderTitle(eventDestinationTitle));
    }, [dispatch, parkingType, destination]);
};
