import { useSelector } from 'react-redux';
import trackSearchLanding from 'segment/pages/search-landing';
import { useEffect } from 'react';
export const useSegmentPageLanding = () => {
    const { referrerAffiliate, searchType, breadcrumbs } = useSelector((state) => state.search.data);
    const breadcrumbCategory = breadcrumbs?.categoryBreadcrumb?.label;
    const destinationCategory = breadcrumbCategory || (searchType === 'event' ? 'Venues' : null);
    useEffect(() => {
        trackSearchLanding(referrerAffiliate, destinationCategory);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
