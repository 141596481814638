import SegmentUtils from 'utils/segment';
import ErrorUtils from 'utils/error-utils';
export const SUGGESTION_TYPE_DESTINATION = 'destination';
export const SUGGESTION_TYPE_EVENT_TITLE = 'event_title';
export const SUGGESTION_TYPE_POPULAR_EVENT = 'popular_event';
function trackSearchSuggestionSelected({ userRawText, description, placeId, parkingType, powerBooking, useCurrentLocation = false, eventId = null, suggestionType = null, }) {
    const properties = {
        user_raw_text: userRawText,
        description,
        place_id: placeId,
        parking_type: parkingType,
        power_booking: powerBooking,
        use_current_location: useCurrentLocation,
        ...(eventId && { event_id: eventId }),
        ...(suggestionType && { suggestion_type: suggestionType }),
    };
    try {
        SegmentUtils.track({
            event: 'Search Suggestion Selected',
            properties,
            additionalOptions: {
                context: {
                    protocols: {
                        event_version: 4,
                    },
                },
            },
        });
    }
    catch (error) {
        ErrorUtils.sendSentryMessage({
            error,
            customErrorMessage: 'Segment Event Failed - trackSearchSuggestionSelected',
        });
    }
}
export default trackSearchSuggestionSelected;
